<template>
  <div/>
</template>

<script>
import { useStore} from "vuex"
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from "vue"
import { naverCallbackUrl, naverClientId } from "@/utils/OAuthLogin";

export default {
  setup: function(){
		const store = useStore();
    const router = useRouter();

    const sessionId = computed(() => store.state.sessionId);
		const userData = computed(() => store.state.userData);
    const isLogin = ref(false);
    const mcd00 = computed(() => store.state.mcd00.mcd00);

    onMounted(() => {
      if(!("R74" in mcd00.value)){ 
        store.dispatch("mcd00/fetchMcd00", {
          code_grp: "R74"
        });
      }
    });

    const login = async (email, id, nickname, phonenumber) => {
      const ip = await fetch('https://api64.ipify.org').then(res=>res.text());
      if(localStorage.getItem("isSnsLink") === "true"){
        router.replace({
            path: "/mypage/sns",
            query: { email, snsJoinFlag: "01", snsCi: id }
        });
        return;
      }
      //console.log(email, id, nickname, ip);

			store.commit("SET_USERDATA", {userData: {
				...userData.value,
				sns_ci: id,
				snsJoinFlag: '01',
			}});
      store.dispatch("mmb01/fetchMmb01", {
          mem_id: email,
          sessionid: sessionId.value,
          ip_addr: ip,
          sns_join_flag: "01",
          sns_ci: id,
          hp_no: phonenumber,
					fcm_token:userData.value.fcmToken,
        }).then(x=>{
          if(x.Res_cd === "-1"){
            store.commit("SET_USERDATA", {userData:{}})
            store.commit("mmb01/SET_SNS_INFO", { email, snsJoinFlag: "01", snsCi: id, snsNickname: nickname, snsPhoneNumber: phonenumber });
            router.replace("/join/step1");
          }
          else if(x.Res_cd === "0000" && x.Res_data.length > 0){
            store.commit("SET_USERDATA", {userData: x.Res_data[0]})
            router.replace("/");
          }
          else if(x.Res_cd === "9999" || x.Res_cd === "9998"){
            store.commit("SET_USERDATA", {userData: {}});
          	store.commit("SET_USERDATA", {userData: x.Res_data[0]})
            alert(x.Res_msg)
            router.replace("/");
          }
          else if(x.Res_cd === "9991"){
            store.commit("SET_USERDATA", {userData: {
						    	...userData.value,
						    	hp_no:phonenumber
						    }
						})
            router.replace("/mypage/snslink");
          }
        });
    }
		const naverLogin = new window.naver.LoginWithNaverId({
			clientId: naverClientId,
			callbackUrl: naverCallbackUrl
		});
		naverLogin.init();

    naverLogin.getLoginStatus((status) => {
      if (status && !isLogin.value) {
        isLogin.value = true;
        //console.log(naverLogin.user);
        const email = naverLogin.user.getEmail();
        const id = naverLogin.user.getId();
        const nickname = naverLogin.user.nickname;
        const phonenumber = naverLogin.user.mobile;
        login(email, id, nickname, phonenumber).then(()=>{
          isLogin.value = false;
        });
	    }
    });
  }
}
</script>