const ENDPOINT = "https://www.hellostay.co.kr"

export const appleClientId = "com.oneglobal.H_SALAD.web.service";
export const appleCallbackUrl = `${ENDPOINT}/callback/apple`;

export const kakaoAppId = "98775ff1ea629324ecaf3ff5c10d7361";
export const kakaoClientId = "a658a17efdc3189ce3464f8b8d16e9c6";
export const kakaoCallbackUrl = `${ENDPOINT}/callback/kakao`;

export const naverClientId = "XEd__C5ffjNijLYPT__H";
export const naverCallbackUrl = `${ENDPOINT}/callback/naver`;
